import React from 'react';
import TextTopBlock from "./TextTopBlock";
import cat from './../images/cat_legal.svg';
import AppLink from "./AppLink";

const Legal = ({downloadRef}) => {
  const mail = <a href="mailto:ar@aizntech.com">ar@aizntech.com</a>

  return (
    <>
    <main>
      <div className='contentLegal'>
        <div className='top-title'>
          <img src={cat} alt='' className='cat-style'/>
          <div className="legalText">
            <h1 className='title'>
              Privacy policy
            </h1>
            <hr/>
            <p>Last Modified: 10th May 2023</p>
          </div>
        </div>
        
        <div className="legalText">
          <h3 className='title'>1. Introdaction</h3>

          <p className='page-text'>Design Barn, Inc., ("Company" or "we, "us", or "our") respect your privacy 
            and are committed to protecting the privacy of our website visitors, customers, 
            business partners and end users, through our compliance with this policy.
          </p>

          <p className='page-text'>This policy describes the types of information we may collect from you or that 
            you may provide when you visit the website https://pocket-look.com or otherwise access 
            our product pages, mobile or web applications, or other digital products that link 
            to or reference this Privacy Policy (collectively with the Website, the "Services") 
            and our practices for collecting, using, maintaining, protecting, and disclosing that 
            information.

            We reserve the right to change this Privacy Policy at any time. We will notify you of 
            any changes to this Privacy Policy by posting a new Privacy Policy to this page, and/or 
            by sending notice to the primary email address specified in your account. You are responsible 
            for ensuring we have an up-to-date active and deliverable email address for you, and for 
            periodically visiting our Website and this privacy policy to check for any changes. Changes 
            to this Privacy Policy are effective when they are posted on this page. You acknowledge that 
            your continued use of our Services after we publish or send a notice about our changes to this 
            Privacy Policy means that the collection, use and sharing of your information is subject to the 
            updated Privacy Policy.
          </p>
          
          <h3 className='title'>2. This policy applies to information we collect:</h3>
          <ul className='page-text'>
            <li>On this Website (regardless of the type of the device or other means you use to access) or through our Services.</li>
            <li>In email, text, and other electronic messages between you and our Services.</li>
            <li>Through mobile and desktop applications, plug-ins, integrations you download from this Website, which provide dedicated non-browser-based interaction between you and this Website.</li>
            <li>When you interact with our applications on third-party websites and services, if those applications include links to this policy.</li>
            <li>While offering our products and services to you.</li>
            <li>Any other sources.</li>
          </ul>

          <h3 className='title'>3. It does not apply to information collected by:</h3>
          <ul className='page-text'>
            <li>Us offline or through any other means, including on any other website operated by Company or any third party; or</li>
            <li>Any third party, including through any application or content (including advertising) that may link to or be accessible from or on the Website.</li>
          </ul> 

          <h3 className='title'>4. Children Under the Age of 16</h3>

          <p className='page-text'>Our Services are not intended for children under 16 years of age. 
          No one under age 16 may provide any personal information to or on the Services. 
          We do not knowingly collect personal information from children under 16. If you are under 16, 
          do not use or provide any information on the Services or through any of its features, register on the 
          Website, make any purchases through the Services, use any of the interactive or public comment 
          features of the Services, or provide any information about yourself to us, including your name, 
          address, telephone number, email address, or any screen name or user name you may use. If we learn 
          we have collected or received personal information from a child under 16 without verification of 
          parental consent, we will delete that information. If you believe we might have any information 
          from or about a child under 16, please contact us at ...
          </p>
          <h3 className='title'>5. Information We Collect About You and How We Collect It</h3>

          <h3 className='title'>We collect several types of information from and about users of our Services, including information:</h3>
          <ul className='page-text'>
            <li>by which you may be personally identified, including name, e-mail address, telephone number, 
              industry information, website or domains associated with you, geographic information, or any 
              other information the Services collects that is defined as personal or personally identifiable 
              information under an applicable law or any other identifier by which you may be contacted online 
              or offline (personal information);
            </li>
            <li>that is about you but individually does not identify you, such as company name and role;</li>
            <li>that you provide by our business partners including our customers, suppliers, and vendors for 
              administration of our business relationship; in connection usage of our products and services, 
              such as data relating to performance and configuration of our products and services, our customer's 
              and end user's consumption of and interaction of such products and services;
            </li>
            <li>that is submitted or uploaded by our customers and their end-users (we process this customer content 
              on behalf of the customer. Our privacy practices will be governed by the contract that we have in place 
              with our customers and this Privacy Policy will not apply to any personal information contained in such 
              customer content,);
            </li>
            <li>about your internet connection, the equipment you use to access our Website and usage details.</li>
          </ul>

          <h3 className='title'>We collect this information:</h3>
          <ul className='page-text'>
            <li>Directly from you when you provide it to us.</li>
            <li>Automatically as you navigate through the site. Information collected automatically may 
            include usage details, IP addresses, and information collected through cookies, web beacons, 
            and other tracking technologies.</li>
            <li>From third parties, for example, our customers or business partners.</li>
          </ul>
          
        </div>
      </div>
    </main>
    <TextTopBlock 
      text1='Contact Information'
      text2='To ask questions or comment about this privacy policy and our privacy practices, contact us at:
       '
       mail={mail}
    />

    <div ref={downloadRef}>
      <AppLink page={'legal'}/>
    </div>
    
    </>
  );
};

export default Legal;
  