import React from 'react';
import footer1 from './../images/footer1.svg';
import footer2 from './../images/footer2.svg';
import applink1 from './../images/app-link1.png';
import applink2 from './../images/app-link2.png';
import footer3 from './../images/cat-footer.svg';

const AppLink = (props) => {
    return (
        <div className={`footer-${props.page}`}>
            <img className='footer-img' src={footer1} alt='' />
            
            <div className='footer-link'>
                <a href="https://testflight.apple.com/join/k1zJ6xx2" target='_blank'><img className='link-app' src={applink1} alt='' /></a>
                <a href="https://play.google.com/store/apps/details?id=com.pocketlook.android" target='_blank'><img className='link-app' src={applink2} alt='' /></a> 
            </div>

            <img className='footer-img' src={footer2} alt='' />
            
            <img className='footer-cat' src={footer3} alt='' />
        </div>
    );
};

export default AppLink;