import React, {useEffect} from "react";
import MainPage from "./MainPage";
import TextTopBlock from "./TextTopBlock";
import banner1 from "./../images/banner1.png"
import banner2 from "./../images/banner2.png"
import banner3 from "./../images/banner4.png"
import phone from "./../images/cat_phone.svg"

import AppLink from "./AppLink";

function Partnershipt({ partnershipRef, downloadRef, handleScroll }) {

  useEffect(() => {    
    handleScroll(partnershipRef.current);  
  });

  return (
    <>
      <MainPage
        imgBanner1={banner1}
        imgBanner2={banner2}
        imgBanner3={banner3}
        para="Personal clothes organiser"
        para2="in your phone!"
        imgLink={phone}
      />

      <TextTopBlock 
        text1='A new experience from using the app as a fashion guide 
          to your clothes'
        text2='Here is an opportunity to make your wardrobe digital with your phone.' 
        text3='Every person at least once in his or her life has puzzled over what to wear today and the Pocket look 
          app helps us to do that.' 
        text4='Users experience a storm of positive emotions while designing their own wardrobe 
          in the app.'
      />

      <div ref={partnershipRef} className="contentText">
        <h1 className='title'>Become a part of PocketLOOK for free</h1>
        <p className='page-text'>Do you want to expand your audience and increase your sales? Join our app, where you can showcase your clothes and accessories to millions of fashion lovers.</p> 

        <p className='page-text'>Our app is a social platform for creating and sharing unique outfits. Users can combine items from different brands and online shops, get feedback from friends and followers, participate in fashion challenges and buy what they like with one click. If you become our partner, you will get access to a huge base of potential customers who are already interested in your products.</p> 

        <p className='page-text'>You will also be able to analyze the preferences and behavior of users to improve your marketing and loyalty strategy. Don’t miss this unique opportunity to collaborate with us! Contact us by email or fill out the form below and we will discuss all the details.</p>

        <div className="forma">
          <form action="https://formspree.io/f/xlekvqlk" method="post">
            <label for="name" className='label-style'>Name</label><br/>
            <input id="name" type='text' name='Name' className='input-style' /><br/><br/>

            <label for="email" className='label-style'>E-mail</label><br/>
            <input id="email" type='email' name='Email' className='input-style' /><br/><br/>
            
            <button type="submit" className='button-sub'>SEND</button>           
          </form>
        </div>
      </div>

      <div ref={downloadRef}>
        <AppLink page={'partnershipt'}/>
      </div>
    </>
  );
}

export default Partnershipt;
