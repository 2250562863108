import React from 'react';

const TextTopBlock = (props) => {

    return (
        <div className='text-top-block'>
            <h1>{props.text1}</h1>
            <h2>{props.text2} {props.mail}</h2>
            <h2>{props.text3}</h2>
            <h2>{props.text4}</h2>
        </div>
    );
};

export default TextTopBlock;