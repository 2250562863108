import React from 'react';

const Convenience = (props) => {
    return (
        <>
            <div className={`convenience ${props.ws}`}>
                {props.image === 'true' ? <div className={` ${props.color} round-avatar ${props.avatar}`}><img style={{width: '55%'}} src={props.icon}/></div>
                : ''}
                <div className={`contentBlock ${props.textWidth} `}>
                <h2 className={`title-block ${props.styletitle} `}>{props.title}</h2>
                    <p>{props.text}</p> 
                </div>     
            </div>
        </>            
    );
};

export default Convenience;