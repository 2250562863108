import React from "react";
import MainPage from "./Pages/MainPage";
import TextTopBlock from "./Pages/TextTopBlock";
import Convenience from "./Pages/Convenience";
import AppLink from "./Pages/AppLink";

import banner1 from "./images/banner1.png"
import banner2 from "./images/banner5.png"
import banner3 from "./images/banner4.png"
import phone from "./images/iphone.svg"
import fox from "./images/fox.svg"
// import phone2 from "./images/cat_phone.png"
// import phone from "./images/cat_phone.svg"
// import phone_m from "./images/cat_phoneM.svg"

import man_json from './animations/Man_lottie.json';
import girl_json from './animations/Woman_lottie.json';
import dress_json from './animations/SQ_lottie.json';

import veshalka from './images/veshalka2.svg';
import home from './images/home.svg';
import profile from './images/profile.svg';

import dress_img from './images/dress2.svg';
import cat2 from './images/cat2.svg';
import fon from './images/fon.png';
import fon2 from './images/fon2.png';

import { Player, Controls } from '@lottiefiles/react-lottie-player';

function Home({downloadRef}) {

  return (
    <>
      <MainPage
        imgBanner1={banner1}
        imgBanner2={banner2}
        imgBanner3={banner3}
        para="Personal clothes organiser"
        para2="in your phone!"
        imgLink={fox}
        imgLink2={phone}
      />

      <TextTopBlock 
        text1='A new experience from using the app as a fashion guide 
          to your clothes'
        text2='Here is an opportunity to make your wardrobe digital with your phone.' 
        text3='Every person at least once in his or her life has puzzled over what to wear today and the Pocket look 
          app helps us to do that.' 
        text4='Users experience a storm of positive emotions while designing their own wardrobe 
          in the app.'
      />

{/* ------------------------------------------*/}
      <div className="section1 convenienceBlock">
        <Convenience 
          color='first-color'
          image='true'
          icon={veshalka}
          title="Convenience"
          text="With a Pocket look app, you can try on clothes anytime and anywhere, without having to physically go to a store. 
            This saves time and effort, especially for people with busy schedules."
          textWidth='convenience-width'
        />

        <Player
          autoplay
          loop
          src={man_json}
          className="maneken"
        >
        </Player>

      </div>

      

      <div className="section2">

        <img className="fon" src={fon} alt=''/>
        
        <div className="block-girl">
          <Convenience 
            color='second-color'
            image='true'
            icon={profile}
            ws='conv'
            avatar='no-avatar'
            title="Personalization"
            text="By entering your figure parameters, the Pocket look app can suggest clothes that will fit you well, 
            based on your body shape and size. This can help you find clothes that flatter your figure and make you feel 
            confident."
            textWidth='personalization-width'
          />

          <div className="girl-avatar">
            <Player
              autoplay
              loop
              src={girl_json}
              className="girl"
            >
            </Player>
            <div className='round-avatar avatar-girl second-color'><img style={{width: '55%'}} src={profile}/></div>
          </div>

        </div> 
      </div>


      <div className="onfooter" >
        <img className="cat" src={cat2} alt=''/>

        <div className="section3">
          <div className="organize">
            <Convenience 
              color='fifth-color'
              image='true'
              icon={home}
              title="Organize"
              text="By allowing users to organize their wardrobe for the week with 
                  tips from a stylist, the app can also help users save time and reduce 
                  stress when getting dressed each day."
              textWidth='organize-width'
            />
          </div>
        </div> 
      </div>
      


      <div className="section4 convenienceBlock">
        <Convenience 
          color='third-color'
          image='true'
          icon={home}
          title="Variety"
          text="With a virtual wardrobe, you can try on a wide range of clothes from different brands and stores, 
          without having to visit each one individually. 
          This gives you access to a greater variety of styles and options. 
          Discover new outfit combinations: By inputting all of your clothing items into the app, you may be 
          able to see new outfit combinations that you hadn't thought of before. This can help you get more use out 
          of your existing wardrobe and feel more creative with your fashion choices."
          textWidth='variety-width'
        />
        <Player
          autoplay
          loop
          src={dress_json}
          className="dress2"
        >
        </Player>
      </div>
      
      
       <div className="section5">   
        <img className="fon" src={fon2} alt=''/> 
       <div className="SustainabilityBlock">
          <div className='round-avatar avatar-sustain fourth-color'><img style={{width: '55%'}} src={veshalka}/></div>
            <Convenience 
              color='fourth-color'
              image='true'
              avatar='no-avatar'
              icon={veshalka}
              title="Sustainability"
              styletitle='sustain-title'
              text="By allowing customers to sell their clothes through the app, 
                it promotes a circular economy and reduces waste. This is because 
                clothes that are no longer needed can be resold to someone else who 
                may want them, rather than being thrown away."
              textWidth='sustainability-width'
            />
        </div>
         <img src={dress_img}  className="dress3" alt=''/>     
      </div> 

      <div className="onfooter2" >
        <img className="cat" src={cat2} alt=''/>

        <div className="section3">
          <div className="organize">
            <Convenience 
              color='fifth-color'
              image='true'
              icon={home}
              title="Organize"
              text="By allowing users to organize their wardrobe for the week with 
                  tips from a stylist, the app can also help users save time and reduce 
                  stress when getting dressed each day."
              textWidth='organize-width'
            />
          </div>
        </div> 
      </div>

      <div ref={downloadRef}>
        <AppLink page={'home'}/>
      </div>
      
    </>
  );
}

export default Home;
