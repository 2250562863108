import React, {useState} from "react";
import { Link } from "react-router-dom";
import logo from "./../images/logo2.svg"
import logoWhite from "./../images/logo_white.svg"
import menu from "./../images/menu-button.svg"
import closebtn from "./../images/close.svg"
import './../index.css'

export const Navbar = ({downloadRef, partnershipRef, handleScroll}) => {

  const [showMenu, setShowMenu] = useState(false);

  const handleMenu = () => {
    setShowMenu(true)
  }

  const handleClose = () => {
    setShowMenu(false)
  }

  const handleScrollClose = () => {
    setShowMenu(false)
    handleScroll(downloadRef.current);
  }

  const handleScrollClose2 = () => {
    setShowMenu(false)
    handleScroll(partnershipRef.current);
  }

  return (
    <>
    <nav id="navbar">
      <a href="/">
        <img
          className="img-logo"
          src={logo}
          alt=''
        />
      </a>
      <ul id="navlist">
        <Link className="link" to="/legal">Legal</Link>
        <Link className="link" onClick={() => {handleScroll(downloadRef.current);}}>Download</Link>
        <Link className="link" to="/partnershipt" >Partnershipt</Link>
      </ul>
      <span className="menu-icon"><img src={menu} alt='' onClick={handleMenu} /></span>
    </nav>

    {showMenu ? 
    <div className="menu-mobile">
      <div className="menu-nav">
        <a href="/">
          <img
            className="img-logo"
            src={logoWhite}
            alt=''
          />
        </a>
        <span className="menu-close"><img src={closebtn} alt='' onClick={handleClose} /></span>
      </div>
      <div className="menu-style">
        <Link className="link-menu" to="/legal" onClick={handleClose}>Legal</Link>
        <Link className="link-menu" onClick={handleScrollClose}>Download</Link>
        <Link className="link-menu" to="/partnershipt" onClick={handleClose}>Partnershipt</Link>
      </div>
    </div>
    : ''}
    </>
  );
};
