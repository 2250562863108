import React, { useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Partnershipt from "./Pages/Partnershipt";
import Legal from "./Pages/Legal";
import {Navbar} from './Pages/Navbar'
import Home from "./Home";

export default function App() {

  const downloadRef = useRef(null); 
  const partnershipRef = useRef(null);

  const handleScroll = (ref) => {
    ref.scrollIntoView()  
  };

  return (
    <div className="App">
      <Router>
        <Navbar downloadRef={downloadRef} partnershipRef={partnershipRef} handleScroll={handleScroll} />
        <Routes>
          <Route path="/" element={<Home downloadRef={downloadRef} />} />
          <Route path="/legal" element={<Legal downloadRef={downloadRef} />} />
          <Route path="/partnershipt" element={<Partnershipt handleScroll={handleScroll} partnershipRef={partnershipRef} downloadRef={downloadRef}/>} />
        </Routes>
      </Router>
    </div>
  );
}
